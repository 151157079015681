import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import GameForm from './form';
import { GameSetup } from './setup';
import { GamePlayers } from './players';

// Decisions
import { GameLeaderboard } from './debrief_leaderboard';
import { GameMaterials } from './debrief_materials';
import { GameDecisions } from './debrief_decisions';
import { GameSurvey } from './debrief_survey';
import { GameAnalysis } from './debrief_analysis';

import { setupSocket, socketOn, enterBackend } from '../../helpers';

const GameItem = () => {
  // Replace mapStateToProps with useSelector
  const game = useSelector((state) => state.game);
  const dispatch = useDispatch();

  // Hooks
  const params = useParams();
  const location = useLocation();

  // Methods
  const reloadGameData = () => {
    console.log(
      `reloadGameData: Game round is ${game.round}. ${
        game?.round ? 'Omitting.' : 'Reloading.'
      }`
    );
    if (!game?.round) dispatch(gameActions.fetchGames(params.id));
  };

  useEffect(() => {
    const { id } = params;
    dispatch(gameActions.fetchGames(id));

    // Fetch game data to connect to socket
    fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
      .then((res) => res.json())
      .then((data) => {
        // Validation
        if (!data?.io_domain) return console.log('No io_domain found');
        // Connect to ws
        setupSocket(data.io_domain, null, (error) => {
          if (error) return console.warn(error);
          if (id) {
            enterBackend({ game_id: id }, (gameData) => {
              console.log(`Connected to backend ${gameData?._id}`);
            });
            // Listen to sockets
            socketOn('player', reloadGameData);
            socketOn('timer_end', reloadGameData);
            socketOn('decision', reloadGameData);
            socketOn('event_flag', reloadGameData);
            socketOn('check_submitted', reloadGameData);
            socketOn('online_players', (online) => {
              console.log('Receiving online_players', online?.length);
              dispatch(gameActions.setData({ online }));
            });
          }
        });
      });
  }, []);

  // Redeclare the socketOn listeners when the game?.round changes
  // This helps us to avoid refreshing the charts and data
  // when the instructor is reviewing a previous run (round)
  useEffect(() => {
    if (game?.round) {
      console.log('Game round changed. Omitting socket listeners.');
      socketOn('player', () => {});
      socketOn('timer_end', () => {});
      socketOn('decision', () => {});
      socketOn('event_flag', () => {});
      socketOn('check_submitted', () => {});
    } else {
      console.log('Game round changed. Reloading socket listeners.');
      socketOn('player', reloadGameData);
      socketOn('timer_end', reloadGameData);
      socketOn('decision', reloadGameData);
      socketOn('event_flag', reloadGameData);
      socketOn('check_submitted', reloadGameData);
    }
  }, [game?.round]);

  // Logic
  const SetupComponent =
    game && game.access_type === 'lti-hbp' ? GameSetup : GameForm;

  // Location validations
  const { pathname } = location;
  const isSaved = game?.status === 'saved' ? true : false;

  // When saved
  const redirectWhenSaved = ['/players', '/debrief'];
  if (isSaved && redirectWhenSaved.some((s) => pathname.includes(s)))
    return <Navigate to={'/games/' + game?._id + '/setup'} />;

  return (
    <div>
      {game && game && (
        <div>
          <Routes>
            {/* Setup */}
            <Route exact path='/*' element={<SetupComponent />} />
            <Route path='/setup/*' element={<SetupComponent />} />

            {/* Debrief */}
            <Route exact path={'/players'} element={<GamePlayers />} />
            <Route
              exact
              path={'/debrief/decisions'}
              element={<GameDecisions />}
            />
            <Route exact path={'/debrief/survey'} element={<GameSurvey />} />
            <Route
              exact
              path={'/debrief/analysis'}
              element={<GameAnalysis />}
            />
            <Route
              exact
              path={'/debrief/leaderboard'}
              element={<GameLeaderboard />}
            />
            <Route exact path={'/materials'} element={<GameMaterials />} />
          </Routes>
        </div>
      )}
    </div>
  );
};

export { GameItem };
