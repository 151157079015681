import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CustomEvents from 'highcharts-custom-events';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import GroupDebrief from '../../components/GroupDebrief';
import DecisionsTable from '../../components/DecisionsTable';
import PresentationBtn from '../../components/PresentationBtn';
import EventButton from '../../components/EventButton';

// Data
import { scenarios } from '../../constants';

// Init Highcharts Custom Events
CustomEvents(Highcharts);

const Decisions = (props) => {
  // Vars
  const { game } = props;
  // const { leaderboard = [] } = game;

  // State
  const [event, setEvent] = useState(1);
  const [force, setForce] = useState(4);

  // const [item, setItem] = useState(null);
  const [group, setGroup] = useState(null);
  const [showTable, setShowTable] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // Effects
  useEffect(() => {
    // Get the decisions list for the game (no snapshot)
    if (params.id) {
      dispatch(gameActions.getDecisionsList(params.id, game.round));
      dispatch(gameActions.getShortDecisionsList(params.id, game.round));
    }
    // // Get the decisions list for a snapshot
    // if (params.id && game.round !== null) {
    //   dispatch(gameActions.getDecisionsList(params.id));
    //   dispatch(gameActions.getShortDecisionsList(params.id));
    // }
  }, [game?.round, params?.id, dispatch]);

  useEffect(() => {
    if (game?.events_ids?.length > 0) setEvent(game.events_ids[0]);
  }, [game?.events_ids]);

  // Functions
  // const rowClick = (data) => {
  //   setGroup(data);
  // };
  const handleEvent = (id) => setEvent(id);
  const handleForce = (id) => setForce(id);

  // Logic
  // console.log(game);

  // Event buttons
  // const eventButtons = scenarios
  //   .find((s) => s.id === game?.scenario)
  //   ?.events.filter((e) => game.events_ids.includes(e.id))
  const eventButtons = game?.events_ids
    ?.map((id) => {
      const theScenario = scenarios.find((s) => s.id === game?.scenario);
      const theEvent = theScenario?.events.find((e) => e.id === id);
      return theEvent;
    })
    .filter((e) => e !== undefined)
    .map((b) => {
      return (
        <div key={b?.id} className='col'>
          <EventButton b={b} event={event} onClick={handleEvent} />
        </div>
      );
    });

  // Force buttons
  // 'Supplier',
  // 'Buyer',
  // 'Substitute',
  // 'Focal Firm',
  // 'Competitor',
  // 'New Entrant',
  const forceButtons = [
    { name: 'Focal Firm', id: 4 },
    { name: 'Supplier', id: 1 },
    { name: 'Buyer', id: 2 },
    { name: 'Competitor', id: 5 },
    { name: 'Substitute', id: 3 },
    { name: 'New Entrant', id: 6 },
  ].map((b) => {
    return (
      <div key={b.id} className='col-12 mt-3'>
        <button
          className={
            b.id === force
              ? 'd-block w-100 btn btn-outline-primary'
              : 'd-block w-100 btn btn-white'
          }
          onClick={() => handleForce(b.id)}>
          {b.name}
        </button>
      </div>
    );
  });

  // Count the decisions per filters (event/force)
  const selectedDecisions = game?.groups
    ? game?.groups
        ?.filter((g) => g.rindex === force)
        ?.reduce((acc, g) => {
          const item = g.items.find((i) => i.event === event) || [];
          if (item) return [...acc, { ...item, type: g.type }];
          else return acc;
        }, [])
    : [];

  const theEvent = game?.short_decisions_list?.[`event_${event}`];
  const fullDecisions = theEvent?.[`force_${force}`];
  const longDecisions =
    game?.decisions_list?.[`event_${event}`]?.[`force_${force}`];
  // const fullDecisions = theEvent?.filter((d) => d.event === `event_${event}`);
  // const decisionsLabels = fullDecisions?.map((fd) => fd.description);
  const decisionsBotSeries = fullDecisions?.map((fd) => {
    const item = selectedDecisions.filter(
      (d) => d?.type === 'bot' && d?.data?.includes(fd.code)
    );
    return [fd.description, item.length];
  });
  const decisionsUserSeries = fullDecisions?.map((fd) => {
    const item = selectedDecisions.filter(
      (d) => d?.type !== 'bot' && d?.data?.includes(fd.code)
    );
    return [fd.description, item.length];
  });
  const decisionsTotalSeries = fullDecisions?.map((fd, fdi) => {
    const total = decisionsBotSeries[fdi][1] + decisionsUserSeries[fdi][1];
    return [fd.description, total];
  });
  const decisionsSortedTotal = decisionsTotalSeries?.sort(
    (a, b) => b[1] - a[1]
  );
  const decisionsSortedUsers = decisionsSortedTotal?.map((d) =>
    decisionsUserSeries.find((u) => u[0] === d[0])
  );
  const decisionsSortedBots = decisionsSortedTotal?.map((d) =>
    decisionsBotSeries.find((b) => b[0] === d[0])
  );

  // User vs Computer Decisions
  // const userDecisions = decisionsSeries?.filter((d) => d?.user);
  // const computerDecisions = selectedDecisions?.filter((d) => !d?.user);

  // Decisions Table
  const tableData = game?.groups
    ?.filter((g) => g.rindex === force && g.type !== 'bot')
    .map((g) => {
      // This event decision
      const decision = g?.items?.find((i) => i.event === event);
      const full = decision?.data?.map((code) =>
        fullDecisions?.find((fd) => fd.code === code)
      );
      // Return the row data
      return {
        world_n: g.world,
        world: (
          <span className='text-nowrap me-2'>
            <FontAwesomeIcon icon={faGlobe} className='text-primary me-1' />{' '}
            <small>World {g?.world}</small>
          </span>
        ),
        role: g.rindex,
        players: g.players.map((p) => ({
          name: p.name,
          lastname: p.lastname,
          email: p.email,
        })),
        decisions: full,
        reflections: decision?.reflections,
      };
    });

  // const hasAnyDecisions = tableData?.some(d => d?.decisions?.length > 0);

  // Render
  return (
    <div>
      <Header />

      <h2 className='mt-3 sec-title'>
        <b>Results</b>: Decisions
        <PresentationBtn />
        <small className='float-end'>
          <DebriefMenu game_id={game._id} location={location} />
        </small>
      </h2>

      <div className='row mt-3 pb-3'>
        <h6 className='mt-0'>Review Event</h6>
        {eventButtons}
      </div>

      <hr className='bg-secondary bg-opacity-50' />

      <div className='row pt-2'>
        <div className='col-2'>
          <h6 className='mt-0'>Decisions by Role</h6>
          <div className='row'>{forceButtons}</div>
        </div>
        <div className='col-10 bg-white rounded-3'>
          <div className='clearfix mt-2 me-2 mb-1'>
            <button
              onClick={() => setShowTable(!showTable)}
              className='btn btn-sm btn-outline-primary float-end'>
              {showTable ? 'Show Chart' : 'Show Table'}
            </button>
          </div>

          {!showTable && (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                title: { text: '' },
                chart: { type: 'column', animation: false },
                credits: { enabled: false },
                legend: { enabled: true },
                xAxis: {
                  title: { text: 'Decisions' },
                  type: 'category',
                  showEmpty: true,
                },
                yAxis: {
                  title: { text: 'Qty' },
                  allowDecimals: false,
                },
                tooltip: {
                  shared: true,
                  formatter: function () {
                    // console.log(this);
                    // const value = this.point.name;
                    const values = this.points.map((p, pi) => {
                      const theShortDecision = fullDecisions.find(
                        (d) => d.description === p.key
                      );
                      const theLongDecision = longDecisions.find(
                        (d) => d.code === theShortDecision.code
                      );
                      const long = theLongDecision?.description || '';

                      // Decision score label
                      let score = 'Decrease';
                      score =
                        theLongDecision?.score === 0 ? 'No Change' : score;
                      score = theLongDecision?.score === 1 ? 'Increase' : score;

                      let text = pi === 0 ? `<b>${long}</b><br/>` : '';
                      text += pi === 0 ? `Decision effect: ${score}<br/>` : '';
                      text += `${p.series.name}: ${p.y}`;
                      return text;
                    });

                    return values.join('<br/>');
                  },
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                    dataLabels: {
                      enabled: true,
                      formatter: function () {
                        return this.y !== 0 ? this.y : '';
                      },
                    },
                  },
                },
                series: [
                  {
                    linkedTo: 'total',
                    name: 'Users',
                    color: '#7E97c3',
                    data: decisionsSortedUsers,
                    showInLegend: true,
                  },
                  {
                    linkedTo: 'total',
                    name: 'Computer',
                    color: '#Ded9c2',
                    data: decisionsSortedBots,
                    showInLegend: true,
                  },
                ],
              }}
            />
          )}
          {showTable && <DecisionsTable data={tableData} />}
        </div>
      </div>

      <Modal
        isOpen={group ? true : false}
        centered={true}
        size='xxl'
        toggle={() => setGroup(null)}>
        <ModalBody className='text-center'>
          {group && group.players.length === game.ppg && (
            <GroupDebrief group={group} />
          )}
          {group && group.players.length !== game.ppg && (
            <p className='mb-0'>Incomplete pair. No debrief available.</p>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedDecisionsPage = connect(mapStateToProps)(Decisions);
export { connectedDecisionsPage as GameDecisions };
