import { gameConstants } from '../constants';
import { history } from '../helpers';
// import { enterGame as wsEnterGame } from '../helpers/sockets';

export const gameActions = {
  setData,
  getData,
  createGame,
  fetchGames,
  updateGame,

  autoAssignPlayers,
  unassignAllPlayers,
  deallocatePlayer,
  swapPlayers,
  setPlayerGroup,
  joinMany,

  launchGame,
  softLaunch,
  addPlayers,
  removePlayers,
  removeGroups,
  addGroups,
  setGroups,
  newRound,
  setRound,
  getLeaderboard,
  getDecisionsList,
  getShortDecisionsList,
  getFeedbackList,
  getForcesData,
  getEventsData,
};

function setData(gameData) {
  return { type: gameConstants.SET_DATA, data: gameData };
}

function getData(id) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + id, (res) => {
      console.log(res);
      // dispatch({ type: gameConstants.SET_DATA, data: res });
    });
  };
}

// New methods
function fetchGames(id) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + (id ? id : ''))
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({ type: gameConstants.SET_DATA, data: res });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function createGame(data) {
  return (dispatch) => {
    console.log('createGame');
    fetch(process.env.REACT_APP_API_URL + '/games', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function updateGame(data) {
  return (dispatch) => {
    dispatch({
      type: gameConstants.SET_DATA,
      data: { ...data, status: 'saving' },
    });
    fetch(process.env.REACT_APP_API_URL + '/games/' + data._id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: res }),
          500
        );
      });
  };
}

function autoAssignPlayers(data) {
  return (dispatch) => {
    dispatch({
      type: gameConstants.SET_DATA,
      data: { ...data, status: 'saving' },
    });
    fetch(
      process.env.REACT_APP_API_URL + '/games/' + data._id + '/autoassign',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: res }),
          500
        );
      });
  };
}

function unassignAllPlayers(data) {
  return (dispatch) => {
    dispatch({
      type: gameConstants.SET_DATA,
      data: { ...data, status: 'saving' },
    });
    fetch(
      process.env.REACT_APP_API_URL + '/games/' + data._id + '/unassignall',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: res }),
          500
        );
      });
  };
}

function setPlayerGroup(data) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(
      process.env.REACT_APP_API_URL + '/games/' + data._id + '/playergroup',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: res }),
          500
        );
      });
  };
}

function deallocatePlayer(data) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(
      process.env.REACT_APP_API_URL +
        '/games/' +
        data._id +
        '/deallocateplayer',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: res }),
          500
        );
      });
  };
}

function swapPlayers(data) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(
      process.env.REACT_APP_API_URL + '/games/' + data._id + '/swapplayers',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: res }),
          500
        );
      });
  };
}

function launchGame(data) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + data._id + '/launch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function softLaunch(data) {
  return (dispatch) => {
    fetch(
      // process.env.REACT_APP_API_URL + '/games/' + data._id + '/softlaunch',
      process.env.REACT_APP_API_URL + '/games/' + data._id + '/launch',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((res) => dispatch(fetchGames(data._id)));
  };
}

function addPlayers(game_id, data) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/players', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function removePlayers(game_id, ids) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });

    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/players', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ids),
    })
      .then((res) => res.json())
      .then((res) => dispatch(fetchGames(game_id)));
  };
}

function removeGroups(game_id, group_ids) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/groups', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(group_ids),
    })
      .then((res) => res.json())
      .then((res) => fetchGames(game_id));
    // .then( res => dispatch({ type: gameConstants.SET_DATA, data: res }) )
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function addGroups(game_id, groups) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/addgroups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groups: groups }),
    })
      .then((res) => res.json())
      .then((res) => dispatch(fetchGames(game_id)));
    // .then( res => dispatch({ type: gameConstants.SET_DATA, data: res }) )
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function setGroups(game_id, group_ids) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/groups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groups: group_ids }),
    })
      .then((res) => res.json())
      .then((res) => dispatch(fetchGames(game_id)));
    // .then( res => dispatch({ type: gameConstants.SET_DATA, data: res }) )
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function newRound(game_id, data) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/snapshot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function setRound(round_id) {
  return { type: gameConstants.SET_ROUND, data: round_id };
}

function getLeaderboard(game_id, round_id = null) {
  return (dispatch) => {
    fetch(
      process.env.REACT_APP_API_URL +
        '/games/' +
        game_id +
        '/leaderboard' +
        (round_id ? '?round=' + round_id : '')
    )
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({
          type: gameConstants.SET_LEADERBOARD,
          data: res?.leaderboard,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getDecisionsList(game_id, round = null) {
  return (dispatch) => {
    const query = round ? '?round=' + round : '';
    fetch(
      process.env.REACT_APP_API_URL +
        '/games/' +
        game_id +
        '/decisionslist' +
        query
    )
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({
          type: gameConstants.SET_DECISIONS_LIST,
          data: res?.list,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getShortDecisionsList(game_id, round = null) {
  return (dispatch) => {
    const query = round ? '?round=' + round : '';
    fetch(
      process.env.REACT_APP_API_URL +
        '/games/' +
        game_id +
        '/shortdecisionslist' +
        query
    )
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({
          type: gameConstants.SET_SHORT_DECISIONS_LIST,
          data: res?.list,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getFeedbackList(game_id, round = null) {
  return (dispatch) => {
    const query = round ? '?round=' + round : '';
    fetch(
      process.env.REACT_APP_API_URL +
        '/games/' +
        game_id +
        '/feedbacklist' +
        query
    )
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({
          type: gameConstants.SET_FEEDBACK_LIST,
          data: res?.list,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getForcesData(game_id, round = null) {
  return (dispatch) => {
    const query = round ? '?round=' + round : '';
    fetch(
      process.env.REACT_APP_API_URL +
        '/games/' +
        game_id +
        '/forcesdata' +
        query
    )
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({
          type: gameConstants.SET_FORCES_DATA,
          data: res?.forces,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getEventsData(game_id) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/eventsdata')
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({
          type: gameConstants.SET_EVENTS_DATA,
          data: res?.events,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function joinMany(game_id, data, cb = null) {
  return (dispatch) => {
    fetch(
      process.env.REACT_APP_API_URL + '/hub/session/' + game_id + '/join_many',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ users: data }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        dispatch(fetchGames(game_id));
        if (cb) cb(res);
      });
  };
}
