import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

// Utils
// import { validateEmail } from '../../helpers/utils';
import {
  // socketOn,
  launchEvent,
  resetEvent,
  resetAllEvents,
} from '../../helpers';

// Actions
import { gameActions, modalActions } from '../../actions';

// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../../components/Header';
// import Reopen from '../../components/Reopen';
import List from './list';
import Logs from '../../components/Logs';

// Constants
import { scenarios } from '../../constants';

const forceNames = [
  'Supplier',
  'Buyer',
  'Substitute',
  'Focal Firm',
  'Competitor',
  'New Entrant',
];

// Check completed events
// const allForcesCompleted = (data, eventsIds = [], forcesIds = []) => {
//   // console.log({ forcesIds });
//   const events = eventsIds.every((event) => {
//     const forces = forcesIds.every((fid) => {
//       // console.log(`event_${event}`, `force_${fid}`);
//       return data?.[`event_${event}`]?.[`force_${fid}`] === true;
//     });
//     return forces;
//   });
//   return events;
// };

const DelayedButton = ({ onClick, delay, children }) => {
  // Use the delay prop to set a countdown
  // using setInterval until the delay is 0
  const [isDisabled, setIsDisabled] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (delay) {
      setIsDisabled(true);
      let innerCounter = 1;
      const timer = setInterval(() => {
        // Check if the delay is 0
        console.log(delay, innerCounter * 1000);
        if (delay === innerCounter * 1000) {
          clearInterval(timer);
          setIsDisabled(false);
        } else {
          setCounter((prev) => prev + 1);
          innerCounter++;
        }
      }, 1000);
    }
  }, [delay]);

  const countDown = delay / 1000 - counter;

  return (
    <button
      disabled={isDisabled}
      className='btn btn-outline-primary'
      onClick={(e) => {
        // setIsDelayed(true);
        onClick(e);
      }}>
      {isDisabled ? `Please wait (${countDown})...` : children}
    </button>
  );
};

const Players = (props) => {
  const { user, game, dispatch } = props;

  // Hooks
  const params = useParams();

  // State
  // const [item, setItem] = useState(null);
  // const [pair_modal, setPairModal] = useState(false);
  // const [new_players, setNewPlayers] = useState([]);
  // const [round, setRound] = useState(null);

  useEffect(() => {
    if (params.id) {
      dispatch(gameActions.fetchGames(params.id));
    }
  }, [params?.id, dispatch]);

  // Methods
  // const addPairModal = () => {
  //   this.setState({ pair_modal: !pair_modal });
  // };

  // const handleNewPlayers = (newPlayers) => {
  //   this.setState({ new_players: newPlayers });
  // };

  // const submitNewPlayers = () => {
  //   const { game } = this.props;
  //   dispatch(gameActions.addPlayers(game._id, new_players));
  //   addPairModal();
  //   this.setState({ new_players: [] });
  // };

  // Instructor Launch Event
  const confirmLaunchEvent = (event_id) => {
    const modalBody = (
      <div className='text-center'>
        <p className='mb-0'>
          Please confirm your decision to launch this event.
        </p>
      </div>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
      <button
        key='modal-btn-confirm'
        className='btn btn-outline-primary'
        onClick={() =>
          launchEvent({ game_id: game?._id, event: event_id }, (res) => {
            dispatch(gameActions.fetchGames(game?._id));
            dispatch(modalActions.close());
          })
        }>
        Confirm
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Confirm Event Launch',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  // const handleEventClick = (event_id) => {
  //   launchEvent({ game_id: game?._id, event: event_id }, (res) => {
  //     dispatch(gameActions.fetchGames(game?._id));
  //     dispatch(modalActions.close());
  //   });
  // };

  // Event Reset
  const eventReset = (w) => {
    // console.log({ eventResetWorld: w });
    resetEvent({ game_id: game?._id, world: w }, (response) => {
      // console.log({ response });
      dispatch(gameActions.fetchGames(params.id));
    });
    dispatch(modalActions.close());
  };

  // Triger Event Reset confirmation modal
  const confirmEventReset = (w, eventLabel = '') => {
    const eventLabelMessage =
      eventLabel?.length > 0 ? <b>{`Event ${eventLabel}`}</b> : '';
    const modalBody = (
      <div className='text-center'>
        <p>Please confirm your decision to reset {eventLabelMessage}.</p>
      </div>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
      <DelayedButton
        key='modal-btn-confirm-wrap'
        delay={5000}
        onClick={() => eventReset(w)}>
        <span>Confirm Reset</span>
      </DelayedButton>,
      //   <button
      //   key='modal-btn-confirm'
      //   className='btn btn-outline-primary'
      //   onClick={() => eventReset(w)}>
      //   Confirm Reset
      // </button>
    ];
    dispatch(
      modalActions.open({
        title: 'Confirm Event Reset',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  // All Events Reset
  const allEventsReset = (w) => {
    resetAllEvents({ game_id: game?._id, world: w }, (response) => {
      dispatch(gameActions.fetchGames(params.id));
    });
    dispatch(modalActions.close());
  };

  // Confirm: Reset all the events
  const confirmAllEventsReset = (w) => {
    const modalBody = (
      <div className='text-center'>
        <p>Please confirm your decision to reset all the events.</p>
      </div>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
      <button
        key='modal-btn-confirm'
        className='btn btn-outline-primary'
        onClick={() => allEventsReset(w)}>
        Confirm Reset
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Confirm Event Reset',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  // Data
  let players =
    game.players && typeof game.players[0] === 'object' ? game.players : [];
  const groups = game.groups || [];

  // Event buttons

  const eventButtons = game?.events_ids
    ?.map((id) => {
      const theScenario = scenarios.find((s) => s.id === game?.scenario);
      const theEvent = theScenario?.events.find((e) => e.id === id);
      return theEvent;
    })
    .filter((e) => e !== undefined)
    .map((b, bi) => {
      // const eventButtons = scenarios
      //   .find((s) => s.id === game?.scenario)
      //   ?.events.filter((e) => game.events_ids.includes(e.id))
      //   .map((b, bi) => {

      const hasTimer = game?.timers?.find(
        (t) => t.event_id === b.id && t.status !== 'snapshot'
      );
      const everyGroupCompleted = game?.groups?.every((g) => {
        // console.log(bi, g?.flags, g?.flags?.[bi]);
        return g?.flags?.[bi];
      });
      // const everyGroupCompleted = allForcesCompleted game?.groups?.every((g) => g?.flags?.[bi]);
      const isPreviousCompleted =
        bi === 0 || (bi > 0 && game?.groups?.every((g) => g?.flags?.[bi - 1]));

      // console.log({ everyGroupCompleted });

      return (
        <div key={b?.id} className='col'>
          <div className='d-flex align-items-center border rounded-3 shadow-sm p-2 bg-light'>
            <div className='lh-1'>
              <small>Event:</small>
              <br />
              <small>
                <b>{b?.title}</b>
              </small>
            </div>

            <div className='ms-auto'>
              {hasTimer && !everyGroupCompleted && (
                <button className='btn btn-sm btn-primary' disabled>
                  Launched
                </button>
              )}
              {!hasTimer && !everyGroupCompleted && (
                <button
                  disabled={!isPreviousCompleted}
                  className={`btn btn-sm ${
                    isPreviousCompleted
                      ? 'btn-primary'
                      : 'btn-light bg-black bg-opacity-10'
                  }`}
                  onClick={(e) => confirmLaunchEvent(b.id)}>
                  Launch
                </button>
              )}
              {everyGroupCompleted && (
                <button className='btn btn-sm btn-secondary' disabled>
                  Completed
                </button>
              )}
            </div>
          </div>
        </div>
      );
    });

  const activeUserPlayers = players
    ?.filter((p) => p?.type === 'user' && p?.active)
    ?.filter((p) =>
      groups.find((g) => g.players.find((gp) => gp._id === p._id))
    );
  const forcesOrder = [4, 1, 2, 5, 3, 6];
  const playersRows = activeUserPlayers
    ?.sort((a, b) => {
      const gindexA = groups?.find((g) =>
        g.players?.find((gp) => gp._id === a._id)
      );
      const gindexB = groups?.find((g) =>
        g.players?.find((gp) => gp._id === b._id)
      );
      return (
        forcesOrder.indexOf(gindexA?.rindex) -
        forcesOrder.indexOf(gindexB?.rindex)
      );
    })
    ?.sort((a, b) => {
      const gindexA = groups?.find((g) =>
        g.players?.find((gp) => gp._id === a._id)
      );
      const gindexB = groups?.find((g) =>
        g.players?.find((gp) => gp._id === b._id)
      );
      return gindexA?.world - gindexB?.world;
    })
    ?.map((p, pi, sortedActivePlayers) => {
      // The group the player is in
      const gindex = groups?.findIndex((g) =>
        g.players?.find((gp) => gp._id === p._id)
      );
      // const isFirstPlayerOfGroup =
      //   groups?.[gindex]?.players?.filter(
      //     (p) => p.type === 'user' && p.active
      //   )?.[0]?._id === p?._id;
      const prevGindex = sortedActivePlayers[pi - 1]
        ? groups?.findIndex((g) =>
            g.players
              ?.filter((p) => p.type === 'user' && p.active)
              .find((gp) => gp._id === sortedActivePlayers[pi - 1]._id)
          )
        : -1;

      const isFirstPlayerOfGroup = gindex !== prevGindex;

      // The magiclink for this player
      const ml =
        process.env.REACT_APP_GAME_URL +
        '?i=' +
        p.game_id +
        '&e=' +
        p.email +
        '&t=' +
        p.token +
        '&k=1';

      // The knowledgecheck for this player
      const kc = p?.check && Object.keys(p?.check)?.length >= 2 ? true : false;
      const kcBlock = (
        <span
          key={`player${p._id}-kch`}
          className={`badge rounded-pill ${
            kc ? 'bg-success' : 'bg-light text-secondary'
          } me-2`}>
          Prep
        </span>
      );

      // The events flagged for this player's group
      const eventBlocks = game?.events_ids
        .filter((e) => e)
        .map((event, ei) => {
          // console.log(groups?.[gindex]?.flags[ei], event);
          let bgColor = 'bg-light text-secondary';
          const hasDecision = groups?.[gindex]?.items.find(
            (i) => i?.event === event
          );
          const hasFlag = groups?.[gindex]?.flags[ei] === true ? true : false;
          bgColor = hasDecision ? 'bg-warning' : bgColor;
          bgColor = hasFlag ? 'bg-success' : bgColor;

          return (
            <span
              key={`player${p._id}-event${event}-${ei}`}
              className={`badge rounded-pill ${bgColor} me-2`}>
              Event {ei + 1}
            </span>
          );
        });

      // Get the world
      const w = groups?.[gindex]?.world || null;
      const isNextWorld = w !== groups?.[prevGindex]?.world;
      const totalPlayersInWorld = isFirstPlayerOfGroup
        ? groups
            ?.filter((g) => g.world === w)
            .reduce(
              (acc, g) =>
                acc +
                g.players?.filter((gp) => gp.type === 'user' && gp?.active)
                  .length,
              0
            )
        : 1;

      // console.log({ totalPlayersInWorld });

      // Has this world an event going on?
      const latestEventsWithDecisions = game?.groups
        .filter((g) => g.world === w && g?.type === 'user')
        ?.map((g) => {
          const latestDecision = g.items[g.items.length - 1];
          const latestDecisionEvent = latestDecision?.event;
          // const latestEventIndex =
          //   game?.events_ids.indexOf(latestDecisionEvent);
          // const hasBeenFlagged = g.flags?.[latestEventIndex] === true;
          // return hasBeenFlagged ? null : latestDecisionEvent;
          // return latestDecisionEvent || undefined;
          return latestDecisionEvent || undefined;
        })
        .filter((e) => !isNaN(e));

      // console.log({ latestEventsWithDecisions });

      // Get the highest event id
      const latestEventForWorld = Math.max(...latestEventsWithDecisions);
      const lastEventForWorldIndex =
        game?.events_ids.indexOf(latestEventForWorld);
      const reseteableEvent =
        !game?.settings_release && latestEventForWorld > 0 ? true : false;
      const eventToResetLabel =
        latestEventForWorld > 0 ? '#' + (lastEventForWorldIndex + 1) : '';

      // Actions
      const theActions = isNextWorld ? (
        <small>
          <button
            disabled={!reseteableEvent}
            onClick={(e) => confirmEventReset(w, eventToResetLabel)}
            className='btn btn-sm btn-link'>
            <small>Reset Event {eventToResetLabel}</small>
          </button>{' '}
          | 
          <button
            disabled={game?.settings_release}
            onClick={(e) => confirmAllEventsReset(w)}
            className='btn btn-sm btn-link'>
            <small>Reset All Events</small>
          </button>
        </small>
      ) : (
        ' '
      );

      return {
        ...p,
        // world: 'World ' + w || '-',
        world:
          (
            <span>
              <FontAwesomeIcon icon={faGlobe} className='text-primary me-1' />
              World {w}
            </span>
          ) || '-',
        group: 1 + gindex,
        count: p.results.length,
        role: forceNames[groups?.[gindex]?.rindex - 1] || '-',
        status: (
          <div>
            {kcBlock} {eventBlocks}
          </div>
        ),
        updated_at: moment(p.updated_at).format('DMMMYY h:mm:ssa'),
        magiclink:
          user.from !== 'lti-hbp' ? (
            <a href={ml} target='_blank' rel='noreferrer'>
              {/* <code style={{ maxWidth: '100px' }}>{ml}</code> */}
              <small>Magic-Link</small>
            </a>
          ) : (
            'N/A'
          ),
        actions: {
          value: theActions,
          colProps: {
            rowSpan: totalPlayersInWorld,
            className: `text-center align-middle ${
              theActions === ' ' ? 'd-none' : ''
            }`,
          },
        },
      };
    });

  // // Validate that all the players have a valid email
  // const validNewPlayers =
  //   new_players.length === 0
  //     ? false
  //     : new_players.every((pl) => {
  //         return validateEmail(pl.email);
  //       });

  // // Validate if there are repeated players
  // const repeatedPlayers = new_players.filter((pl, pli) => {
  //   return (
  //     players.find((p) => p.email === pl.email) ||
  //     new_players.find((p, pi) => p.email === pl.email && pli !== pi)
  //   );
  // });

  // console.log(players);
  const tableColumnsLabels =
    game?.sample === true
      ? [
          'World',
          'Role',
          'First Name',
          'Last Name',
          'Last Activity',
          // 'Email',
          'Progress',
        ]
      : [
          'World',
          'Role',
          'First Name',
          'Last Name',
          'Last Activity',
          // 'Email',
          'Progress',
          'MagicLink',
          'Actions',
        ];

  const tableColumnsFields =
    game?.sample === true
      ? [
          'world',
          'role',
          'name',
          'lastname',
          'updated_at',
          // 'email',
          'status',
        ]
      : [
          'world',
          'role',
          'name',
          'lastname',
          'updated_at',
          // 'email',
          'status',
          'magiclink',
          'actions',
        ];

  return (
    <div>
      <Header />

      <h2 className='mt-3 sec-title clearfix'>
        <span className='float-start'>
          Run Progress{' '}
          <small className='d-inline-block me-3 fs-5 fw-normal'>
            {game?.started_at
              ? moment(game?.started_at).format('DMMMYY h:mm:ssa')
              : ''}
          </small>
          <small
            className='badge bg-primary'
            style={{
              fontSize: '12px',
              padding: '5px 8px',
              verticalAlign: 'middle',
            }}>
            {activeUserPlayers.length} Active Players
          </small>
        </span>

        <small className='float-end'>
          {/* {user.from !== 'lti-hbp' && (
            <button className='btn btn-outline-primary' onClick={addPairModal}>
              Add Player
            </button>
          )} */}
          <button
            className='btn btn-outline-primary ms-3'
            onClick={(e) => window.location.reload()}>
            <FontAwesomeIcon icon='redo-alt' />
          </button>
        </small>
      </h2>

      {/* Events Release */}
      {game?.settings_release && !game?.round && (
        <div className='row mb-3'>{eventButtons}</div>
      )}

      <List
        className='table-sm'
        labels={tableColumnsLabels}
        fields={tableColumnsFields}
        data={playersRows}
      />

      {/* Logs only visible to administrators */}
      {user?.role === 'administrator' && <Logs game={game} />}
    </div>
  );
};

function mapStateToProps(state) {
  const { player, game, authentication } = state;
  const { user } = authentication;
  return {
    user,
    game,
    player,
  };
}

const connectedPlayersPage = connect(mapStateToProps)(Players);
export { connectedPlayersPage as GamePlayers };
