import io from 'socket.io-client';

let socket;
// let auth = {};

export const setupSocket = (url, opts = {}, cb) => {
  if (socket?.connected) return console.log('Socket already connected.');

  const defaultOpts = opts || { withCredentials: true };
  socket = io(url, defaultOpts);
  socket.on('connect', (s) => {
    console.log('Connected!', socket?.id);
    // if (!socket?.id) return socket.connect();
    cb && cb();
  });

  socket.on('data', (data) => {
    console.log('Data', data);
  });

  socket.on('error', (error) => {
    console.warn(error);
    cb && cb({ error });
  });

  socket.on('connect_error', (error) => {
    console.warn(error);
    cb && cb({ error });
    // setTimeout(() => {
    //   socket = io(url, defaultOpts);
    // }, 1000);
  });

  socket.on('disconnect', (reason) => {
    console.warn(reason);
    // cb && cb({ reason });
  });
};

export const socketOn = (msg, cb) => {
  console.log('socketOn', msg, cb ? 'cb' : 'no cb');
  socket?.off(msg);
  socket?.on(msg, cb);
};

export const enterGame = (data, cb) => {
  socket.emit('enter', data, (gameData) => {
    // auth = data;
    cb(gameData);
  });
};

export const enterBackend = (data, cb) => {
  socket.emit('enter-backend', data, (gameData) => {
    // auth = data;
    cb(gameData);
  });
};

// Sim-specific
export const launchEvent = (data, cb) => {
  socket.emit('backend-launch-event', data, (gameData) => {
    // auth = data;
    cb(gameData);
  });
};

export const resetEvent = (data, cb) => {
  socket.emit('backend-reset-event', data, (gameData) => {
    // auth = data;
    cb && cb(gameData);
  });
};

export const resetAllEvents = (data, cb) => {
  socket.emit('backend-reset-all-event', data, (gameData) => {
    // auth = data;
    cb && cb(gameData);
  });
};
