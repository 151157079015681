import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
// import numeral from 'numeral';

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Actions
// import { gameActions } from '../../actions';

// import { Modal, ModalBody, Tooltip } from 'reactstrap';
import Header from '../../components/Header';
import PresentationBtn from '../../components/PresentationBtn';
import { DebriefMenu } from './debrief_menu';
// import GroupDebrief from '../../components/GroupDebrief';
// import { useEffect } from 'react';

// Data
// import { scenarios } from '../../constants';

const Survey = (props) => {
  // Vars
  const { game } = props;
  // const { leaderboard = [] } = game;

  // State
  // const [event, setEvent] = useState(1);
  const [force, setForce] = useState(4);

  // const [item, setItem] = useState(null);
  // const [group, setGroup] = useState(null);
  // const [tooltips, setTooltips] = useState({});

  // Hooks
  // const dispatch = useDispatch();
  // const params = useParams();
  const location = useLocation();

  // // Effects
  // useEffect(() => {
  //   if (params.id) dispatch(gameActions.getDecisionsList(params.id));
  // }, []);

  // Functions
  // const rowClick = (data) => {
  //   setGroup(data);
  // };
  // const handleEvent = (id) => setEvent(id);
  const handleForce = (id) => setForce(id);

  // Logic
  // console.log(game);

  // Force buttons
  const forceButtons = [
    { name: 'Focal Firm', id: 4 },
    { name: 'Supplier', id: 1 },
    { name: 'Buyer', id: 2 },
    { name: 'Competitor', id: 5 },
    { name: 'Substitute', id: 3 },
    { name: 'New Entrant', id: 6 },
  ].map((b) => {
    return (
      <div key={b.id} className='col-12 mt-3'>
        <button
          className={
            b.id === force
              ? 'd-block w-100 btn btn-outline-primary'
              : 'd-block w-100 btn btn-white'
          }
          onClick={() => handleForce(b.id)}>
          {b.name}
        </button>
      </div>
    );
  });

  const surveysPre = game?.players
    ?.filter((p) => p.type !== 'bot')
    .map((p) => {
      return p?.survey_initial &&
        Object.keys(p?.survey_initial).length === 6 &&
        Object.keys(p?.survey_initial).every(
          (k) => p?.survey_initial[k] !== null
        )
        ? p?.survey_initial
        : null;
    });

  const surveysPost = game?.players
    ?.filter((p) => p.type !== 'bot')
    .map((p) => {
      return p?.survey_final &&
        Object.keys(p?.survey_final).length === 6 &&
        Object.keys(p?.survey_final).every((k) => p?.survey_final[k] !== null)
        ? p?.survey_final
        : null;
    });

  const totalPre = surveysPre?.reduce(
    (acc, s) => {
      if (!s) return acc;

      const newAcc = [...acc];
      const selectedForceValue = s[`force_${force}`];
      // Add +1 to the selected force value (High, Medium, Low)
      if (
        typeof selectedForceValue !== 'undefined' &&
        selectedForceValue !== null
      )
        newAcc[2 - selectedForceValue] += 1;
      return newAcc;
    },
    [0, 0, 0]
  ) || [0, 0, 0];

  const totalPost = surveysPost?.reduce(
    (acc, s) => {
      if (!s) return acc;

      const newAcc = [...acc];
      const selectedForceValue = s[`force_${force}`];
      // Add +1 to the selected force value (High, Medium, Low)
      if (
        typeof selectedForceValue !== 'undefined' &&
        selectedForceValue !== null
      )
        newAcc[2 - selectedForceValue] += 1;
      return newAcc;
    },
    [0, 0, 0]
  ) || [0, 0, 0];

  // console.log({ surveysPre, surveysPost, totalPre, totalPost });

  // const someGroupDecided = game?.groups?.some((g) => g?.items?.length > 0);
  // console.log({ someGroupDecided });

  // Render
  return (
    <div>
      <Header />

      <h2 className='mt-3 sec-title'>
        <b>Results</b>: Industry Assessment
        <PresentationBtn />
        <small className='float-end'>
          <DebriefMenu game_id={game._id} location={location} />
        </small>
      </h2>

      <div className='row pt-3'>
        <div className='col-2'>
          <h6 className='mt-0'>Perceived Power</h6>
          <div className='row'>{forceButtons}</div>
        </div>
        <div className='col-10 bg-white rounded-3 pt-3'>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {
                text: '',
              },
              chart: { type: 'column', animation: false },
              credits: { enabled: false },
              legend: { enabled: true },
              xAxis: {
                title: { text: 'Power' },
                categories: ['Low', 'Medium', 'High'],
                showEmpty: true,
              },
              yAxis: {
                title: { text: 'Count' },
                allowDecimals: false,
              },
              plotOptions: {
                column: {
                  dataLabels: {
                    enabled: true,
                    formatter: function () {
                      return this.y !== 0 ? this.y : '';
                    },
                  },
                },
              },
              series: [
                {
                  name: 'Initial',
                  data: totalPre,
                  color: '#7E97c3',
                },
                {
                  name: 'Final',
                  data: totalPost,
                  color: '#Ded9c2',
                },
              ],
            }}
          />

          <p className='text-center fs-6 fw-light' style={{ color: '#555' }}>
            <small>
              Initial Assessment respondents:{' '}
              {surveysPre?.filter((s) => s).length}/
              {
                game?.players?.filter((p) => p.active && p.type !== 'bot')
                  ?.length
              }{' '}
              | Final Assessment respondents:{' '}
              {surveysPost?.filter((s) => s).length}/
              {
                game?.players?.filter((p) => p.active && p.type !== 'bot')
                  ?.length
              }
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedSurveyPage = connect(mapStateToProps)(Survey);
export { connectedSurveyPage as GameSurvey };
